import Gallery from "react-photo-gallery";
import Gap from "../../../../src/@lekoarts/gatsby-theme-cara/elements/gap.tsx";
import Text from "../../../../src/@lekoarts/gatsby-theme-cara/elements/text.tsx";
import ImageText from "../../../../src/@lekoarts/gatsby-theme-cara/elements/imagetext.tsx";
import TextLayout from "../../../../src/@lekoarts/gatsby-theme-cara/elements/textlayout.tsx";
import Annotation from "../../../../src/@lekoarts/gatsby-theme-cara/elements/annotation.tsx";
import Title from "../../../../src/@lekoarts/gatsby-theme-cara/elements/title.tsx";
import Images from "../../../../src/@lekoarts/gatsby-theme-cara/elements/images.tsx";
import AliceCarousel from "react-alice-carousel";
import PhotoGallery from "../../../../src/@lekoarts/gatsby-theme-cara/elements/gallery.tsx";
import "react-alice-carousel/lib/alice-carousel.css";
import * as React from 'react';
export default {
  Gallery,
  Gap,
  Text,
  ImageText,
  TextLayout,
  Annotation,
  Title,
  Images,
  AliceCarousel,
  PhotoGallery,
  React
};