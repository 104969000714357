import ImageText from "../../../../src/@lekoarts/gatsby-theme-cara/elements/imagetext.tsx";
import Title from "../../../../src/@lekoarts/gatsby-theme-cara/elements/title.tsx";
import Gap from "../../../../src/@lekoarts/gatsby-theme-cara/elements/gap.tsx";
import AliceCarousel from "react-alice-carousel";
import Annotation from "../../../../src/@lekoarts/gatsby-theme-cara/elements/annotation.tsx";
import TextLayout from "../../../../src/@lekoarts/gatsby-theme-cara/elements/textlayout.tsx";
import PhotoGallery from "../../../../src/@lekoarts/gatsby-theme-cara/elements/gallery.tsx";
import "react-alice-carousel/lib/alice-carousel.css";
import * as React from 'react';
export default {
  ImageText,
  Title,
  Gap,
  AliceCarousel,
  Annotation,
  TextLayout,
  PhotoGallery,
  React
};