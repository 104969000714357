import Gap from "../../../../src/@lekoarts/gatsby-theme-cara/elements/gap.tsx";
import Text from "../../../../src/@lekoarts/gatsby-theme-cara/elements/text.tsx";
import ImageText from "../../../../src/@lekoarts/gatsby-theme-cara/elements/imagetext.tsx";
import Title from "../../../../src/@lekoarts/gatsby-theme-cara/elements/title.tsx";
import Images from "../../../../src/@lekoarts/gatsby-theme-cara/elements/images.tsx";
import Annotation from "../../../../src/@lekoarts/gatsby-theme-cara/elements/annotation.tsx";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import * as React from 'react';
export default {
  Gap,
  Text,
  ImageText,
  Title,
  Images,
  Annotation,
  AliceCarousel,
  React
};